import React, {useEffect} from 'react';
import './App.css'
import Router from './Router'
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import ColorTheme from './styles/ColorTheme';
import StackMargin from './styles/StackMargin';
import { StyledEngineProvider } from '@mui/material/styles';
import posthog from 'posthog-js'
import { ClerkProvider, SignedOut, RedirectToSignIn } from '@clerk/clerk-react'

// Import your publishable key
const PUBLISHABLE_KEY = 'pk_live_Y2xlcmsubWlyd29yay5haSQ'

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}

function App() {
  useEffect(() => {
      posthog.init('phc_5d1EFkPjd4xdWCOBuS8bgYMrqnVnvZfjrOoRVsOcGbi',
      {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
        autocapture: false,
        loaded: (posthog) => {
          if (posthog) {
            console.log('PostHog successfully loaded');
            posthog.capture('Enter_app', { message: 'User entering Mirwork' });
          } else {
            console.error('Failed to load PostHog');
          }
        },
      });
    for (const [key, value] of Object.entries(ColorTheme.palette.primary)) {
      document.documentElement.style.setProperty(`--color-primary-${key}`, value);
    }
    for (const [key, value] of Object.entries(ColorTheme.palette.secondary)) {
      document.documentElement.style.setProperty(`--color-secondary-${key}`, value);
    }
  }, []);

  return (
    <div className="App">    
      {/* <ClerkProvider publishableKey="pk_test_bmV4dC1zaWxrd29ybS02My5jbGVyay5hY2NvdW50cy5kZXYk" afterSignOutUrl="/"> */}
      <ClerkProvider publishableKey="pk_live_Y2xlcmsubWlyd29yay5haSQ" afterSignOutUrl="/">
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
        <Auth0Provider      
          domain="dev-kc2nq83kxviumxz4.us.auth0.com"
          clientId="COFYa1xwMrjWMa9ZXHQcOYXvn85gLx36"
          client_name="Test Mirwork"
          authorizationParams={{
            redirect_uri: `${process.env.REACT_APP_FRONTEND_URL}/home`
          }}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        >
          <StyledEngineProvider injectFirst>
          <ThemeProvider theme={ColorTheme}>
          <ThemeProvider theme={StackMargin}>
            <Router />
          </ThemeProvider>
          </ThemeProvider>
          </StyledEngineProvider>
        </Auth0Provider>
      </ClerkProvider>
    </div>
  );
}

export default App
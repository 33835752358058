import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Card, CardContent, CardActions, Grid, Divider } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { loadStripe } from '@stripe/stripe-js';
import { TopBar } from '../../components/top_bar/TopBar';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';
import { GetUser } from '../../components/auth/GetUser';
import './Pricing.css';
import posthog from 'posthog-js';

const planNames = { 
    FreeStarter : 'Free Starter',
    Monthly : 'Monthly Premium',
    Annual : 'Annual Pro',
}

const PricingCard = ({ planName, price, priceSubText, features, buttonText, onSubscribe,
    isCurrentPlan,
    isPremium,
    premiumUntil,
}) => {
    const cardClassName =
        planName === planNames.FreeStarter
            ? 'pricingCard'
            : isCurrentPlan
            ? 'pricingCard-current'
            : 'pricingCard-premium';
    return (
        <Card
            className={cardClassName} sx={{ position: 'relative', overflow: 'visible'}}
        >
            {planName === planNames.Annual && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'green',
                        color: 'white',
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        padding: '4px 8px',
                        borderRadius: '6px',
                        zIndex: 10,
                    }}
                >
                    Best Price, Save 72%
                </Box>
            )}
            <CardContent sx={{ height: '380px' }}>
                <Typography className='subtitle-text' gutterBottom>
                    {planName}
                </Typography>
                <Typography className='title-text-light-huge' style={{ marginBottom: '5px' }}>
                    {price}
                </Typography>
                <Typography className='normal-text-mini-grey-bold'>
                    {priceSubText}
                </Typography>
                <Divider sx={{ marginY: 2 }} />
                <ul style={{ listStyleType: 'none', paddingLeft: 0, textAlign: 'left' }}>
                    {features.map((feature, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            <DoneIcon sx={{ color: 'blue', fontSize: 'small', marginRight: '8px' }} />
                            <Typography className='title-text-mini'>
                                {feature}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </CardContent>
            <CardActions>
                {isCurrentPlan && isPremium && planName !== planNames.FreeStarter ? (
                    <Typography className='title-secondary-mini' sx={{ marginBottom: '25px' }}>
                        Membership will end on {new Date(premiumUntil).toLocaleDateString()}
                    </Typography>
                ) : (
                    <Button className='main-button-middle' onClick={onSubscribe}>
                        {buttonText}
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};

const App = () => {
    const [curuser, setCuruser] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentCanceled, setPaymentCanceled] = useState(false);

    const { isSignedIn, user, isLoaded } = useUser();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


    const handleSubscribe = async (plan) => {
        if (plan === planNames.FreeStarter) {
            posthog.capture('payment_free_plan_clicked', {
                plan_name: plan,
                userID: curuser.id
              })
            window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/interview`;
            return;
        }

        posthog.capture('payment_plan_clicked', {
            plan_name: plan,
            userID: curuser.id
          })

        if (plan === planNames.Monthly) {
            posthog.capture('payment_month_plan_clicked', {
                plan_name: plan,
                userID: curuser.id
              })
        } else {
            posthog.capture('payment_year_plan_clicked', {
                plan_name: plan,
                userID: curuser.id
              })
        }

        const stripe = await stripePromise;

        try {
            const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/userData/create-checkout-session";
            const response = await axios.post(apiUrl, {
                userID: curuser.id,
                plan: plan,
            });
            const session = response.data;

            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                console.error(result.error.message);
            }
        } catch (error) {
            console.error("Error creating checkout session:", error);
        }
    };

    useEffect(
        () => {
            const fetchUserData = async () => {
                if (isLoaded) {
                    console.log(user)
                    let curUser = await GetUser(isSignedIn, user);
                    setCuruser(curUser)
                    console.log(curUser)
                    posthog.identify(user.primaryEmailAddress.emailAddress)
                }
            };
            fetchUserData();
        }, [isSignedIn, user, isLoaded]);

    useEffect(() => {
        posthog.capture('$pageview', {
            $current_url: window.location.href,
            $host: window.location.hostname,
            $pathname: window.location.pathname,
        });
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('success')) {
            setPaymentSuccess(true);
        }
        if (queryParams.get('canceled')) {
            setPaymentCanceled(true);
        }
    }, []);

    const pricingPlans = [
        {
            planName: planNames.FreeStarter,
            price: 'Free',
            priceSubText: 'Perfect for first-time users to get a feel for interview practice.',
            features: [
                '3 mock interviews per month',
                '15-min meeting limit per practice',
                'Limited interview roles & questions',
                'Basic feedback',
            ],
            buttonText: 'Try it now',
            onSubscribe: () => handleSubscribe(planNames.FreeStarter)
        },
        {
            planName: planNames.Monthly,
            price: '$29.99',
            priceSubText: 'Ideal for serious job seekers looking to build confidence and skills.',
            features: [
                '30-min meeting limit per practice',
                'Access to industry-validated question bank',
                'Detailed feedback reports (including interview transcripts, communication score, Leadership evaluation)',
                'Exclusive Discord channel with experts and peers',
            ],
            buttonText: 'Buy Now',
            onSubscribe: () => handleSubscribe(planNames.Monthly)
        },
        {
            planName: planNames.Annual,
            price: '$99.99',
            priceSubText: 'Perfect for ambitious candidates ready to land their dream MANNG job.',
            features: [
                'Unlimited practice',
                '45-min extended meeting limit per practice',
                'Regularly updated questions bank that are vetted by community experts contribution',
                'Personalized feedback reports with details for area of improvement',
                '24/7 Exclusive Discord channel & Coaching Network',
            ],
            buttonText: 'Buy Now',
            onSubscribe: () => handleSubscribe(planNames.Annual)
        },
    ];

    return (
        <Box>
            <Box padding={4}>
                <Typography className='title-text-huge' gutterBottom style={{ textAlign: 'center', marginBottom: '10px' }}>
                    Sign up today and start practicing interview
                </Typography>
                <Typography className='normal-text-small-main' style={{ textAlign: 'center', marginBottom: '30px' }}>
                    Begin your professinal interview practice
                </Typography>
                <Box sx={{ width: '90%', margin: '0 auto' }}>
                    <Grid container spacing={10} justifyContent="center">
                        {pricingPlans.map((plan, index) => (
                            <Grid item key={index}>
                                <PricingCard
                                    {...plan}
                                    isCurrentPlan={(curuser?.subscriptionLevel || planNames.FreeStarter) === plan.planName}
                                    isPremium={curuser?.premium}
                                    premiumUntil={curuser?.premiumUntil}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box sx={{ alignItems: 'center', justifyContent: 'center', }}>
                    {paymentSuccess && (
                        <Box
                            sx={{
                                bgcolor: 'success.main',
                                color: 'white',
                                padding: 2,
                                borderRadius: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: 4,
                            }}
                        >
                            <Typography className='text-white-mini-bold'>
                                Payment Successful! You are now a Premium Member.
                            </Typography>
                        </Box>
                    )}
                    {paymentCanceled && (
                        <Box
                            sx={{
                                bgcolor: 'error.main',
                                color: 'white',
                                padding: 1,
                                borderRadius: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: 4,
                            }}
                        >
                            <Typography className='text-white-mini-bold'>
                                Payment was canceled. Please try again if you wish to subscribe.
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};




export default () => {
    return (
        <>
            <TopBar />
            <App />
        </>
    );
};  

import React, { useState, useEffect } from 'react';
import MainScreen from '../../components/interview/main_screen/MainScreen';
import SpeechToText from '../../components/interview/chat_history/speech-to-text';
import Footer from '../../components/interview/footer/Footer';
import WaitingRoom from '../../components/interview/waiting_room/WaitingRoom';
import CreateInterview from '../create_interview/CreateInterview';
import CompleteInterview from '../../components/interview/complete_interview/CompleteInterview';
import { useInterval } from '../../utils/useInterval';
import { TopBar } from '../../components/top_bar/TopBar';
import { GetUser } from '../../components/auth/GetUser';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import posthog from 'posthog-js';

const Interview = () => {
    const [globalResume, setGlobalResume] = useState("");
    const [globalJD, setGlobalJD] = useState("");
    const [globalRound, setGlobalRound] = useState("");
    const [mic, setMic] = useState(false);
    const [video, setVideo] = useState(false);
    const [cc, setCc] = useState(false);
    const [language, setLanguage] = useState("en");
    const [isIntvSpeaking, setIsIntvSpeaking] = useState(false);
    const [isCandSpeaking, setIsCandSpeaking] = useState(false);
    const [hasUserJoined, setHasUserJoined] = useState(false);
    const [hasEndButtonClicked, setEndButtonClicked] = useState(false);
    const [interviewPromptId, setInterviewPromptId] = useState("");
    const [interviewQuestion, setInterviewQuestion] = useState("");
    

    const { isSignedIn, user, isLoaded } = useUser()
    const [candidateEmail, setCandidateEmail] = useState("");
    const [candidateFirstName, setCandidateFirstName] = useState("Candidate");
    const [candidateLastName, setCandidateLastName] = useState("Candidate");
    const [candidateImg, setCandidateImg] = useState("");
    const [quickMockJobId, setQuickMockJobId] = useState("");
    const [jT, setJT] = useState("");
    const [cN, setCN] = useState("");

    const [sessionId, setSessionId] = useState(null);
    const [jobDataReady, setJobDataReady] = useState(false);

    const updateURLWithQMJid = (QMJid) => {
        const url = new URL(window.location);
    
        url.searchParams.delete('sessionId');
    
        const otherParams = url.searchParams.toString();
        
        console.log(otherParams);
    
        const newUrl = `${url.origin}${url.pathname}?QMJid=${QMJid}${otherParams ? '&' + otherParams : ''}`;
    
        window.history.replaceState({}, '', newUrl);
    };

        useEffect(() => {
        posthog.capture('$pageview', {
            $current_url: window.location.href,
            $host: window.location.hostname,
            $pathname: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        const getUser_ = async () => {
            let curUser = await GetUser(isSignedIn, user);
            if(curUser["firstName"]){
                setCandidateFirstName(curUser["firstName"])
                setCandidateLastName(curUser["lastName"])
            }
        }

        if(isSignedIn) {
            setCandidateImg(user.imageUrl);
            setCandidateEmail(user.primaryEmailAddress.emailAddress);
            posthog.identify(user.primaryEmailAddress.emailAddress);
            // set candidate name
            getUser_();
        }
      }, [isSignedIn])

    useEffect(() => {   
        posthog.capture('$pageview', {
            $current_url: window.location.href,
            $host: window.location.hostname,
            $pathname: window.location.pathname,
        });

        const handleQuickMock = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const QMJid = urlParams.get('QMJid');
            const sessionIdParam = urlParams.get('sessionId');
            const pluginJD = urlParams.get('pluginJD');
            const companyName = urlParams.get('companyName');
            const companyLogoURL = urlParams.get('companyLogoURL');
            const jobTitle = urlParams.get('jobTitle');
            const roundParam = urlParams.get('round');
            const languageParam = urlParams.get('language');


            if (QMJid) {
                setQuickMockJobId(QMJid);
                setGlobalRound('Quick Mock');
                setJT(jobTitle);
                setCN(companyName);
            } else if (sessionIdParam) {
                setSessionId(sessionIdParam);
                setGlobalRound('Quick Mock');
                setJT(jobTitle);
                setCN(companyName);
            } else if (pluginJD) {
                setGlobalRound('Quick Mock');
                setJT(jobTitle);
                setCN(companyName);

                const dataToSend = {
                    jobdescription: pluginJD,
                    companyName: companyName || "none",
                    companyLogoURL: companyLogoURL || "none",
                    jobTitle: jobTitle || "none", 
                };
                const headers = {
                    'Content-Type': 'application/json',
                }
                const url = process.env.REACT_APP_BACKEND_URL + "/api/openai/generateSystemPrompt";
                console.log('[INFO] Fetching system prompt generation');

                try {
                    const response = await axios.post(url, dataToSend, { headers });
                    const { quickMockJobId: fetchedQMJid, message } = response.data;
                    if (fetchedQMJid) {
                        setQuickMockJobId(fetchedQMJid);
                        updateURLWithQMJid(fetchedQMJid);
                        setJT(jobTitle); 
                        setCN(companyName);
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                if (roundParam) { 
                    setGlobalRound(roundParam);
                }
            }

            if (languageParam) {
                setLanguage(languageParam);                
            }
            if (companyName) {
                setCN(companyName);
            }
            if (jobTitle) {
                setJT(jobTitle);
            }
        };

        handleQuickMock();
    }, []);

    useInterval(async () => {
        if (sessionId && !jobDataReady) {

            const url = process.env.REACT_APP_BACKEND_URL + "/api/userData/JobStatus";
            const dataToSend = {
                sessionId: sessionId,
            };

            try {
                console.log(url, dataToSend);
                const response = await axios.post(url, {
                    sessionId: sessionId,
                });
                if (response.data && response.data.quickMockJobId) {
                    setQuickMockJobId(response.data.quickMockJobId);
                    updateURLWithQMJid(response.data.quickMockJobId);
                    setJT(response.data.jobTitle);
                    setCN(response.data.companyName);
                    setJobDataReady(true);
                }
            } catch (error) {
                console.error('[ERROR] Error while checking job data readiness:', error);
            }
        }
    }, 1000); 

    if(!globalRound){
        return(
            <CreateInterview
                setInterview={(resume, jd, round) => {
                    setGlobalResume(resume);
                    setGlobalJD(jd);
                    setGlobalRound(round);
                }}
            />
        )
    } else {
        return( 
        <>
            { !hasUserJoined &&
                <>
                <TopBar />
                <WaitingRoom
                    isVideoOnGlobal={video}
                    setVideoGlobal={setVideo}
                    isMicOnGlobal={mic}
                    setMicGlobal={setMic}
                    setHasUserJoined={setHasUserJoined}
                    round={globalRound}
                    candidate={candidateFirstName}
                    candidateImg={candidateImg}
                    language={language}
                    quickMockJobId={quickMockJobId}
                    companyName = {cN}
                    jobTitle = {jT}
                    isSignedIn = {isSignedIn}
                />
                </>
            }
            {
              hasUserJoined && !hasEndButtonClicked &&
                <>
                <MainScreen
                    isVideoOn={video}
                    isMicOn={mic}
                    isccOn={cc}
                    isIntvSpeaking={isIntvSpeaking}
                    isCandSpeaking={isCandSpeaking}
                    candidateImg={candidateImg}
                    candidate={candidateFirstName}
                />
                <SpeechToText
                    isMicOn={mic}
                    isccOn={cc}
                    resume={globalResume}
                    jd={globalJD}
                    candidate={candidateFirstName}
                    round={globalRound}
                    language={language}
                    setIntvSpeaking={setIsIntvSpeaking}
                    setCandSpeaking={setIsCandSpeaking}
                    setInterviewPrompt={(id, question) => {
                        setInterviewPromptId(id);
                        setInterviewQuestion(question);
                    }}
                    quickMockJobId={quickMockJobId}
                />
                <Footer
                    isVideoOn={video}
                    isMicOn={mic}
                    onMicClick={setMic}
                    onVideoClick={setVideo}
                    onccClick={setCc}
                    hasUserJoined={hasUserJoined}
                    candidateEmail={candidateEmail}
                    round={globalRound}
                    interviewPromptId={interviewPromptId}
                    interviewQuestion={interviewQuestion}
                    hasEndButtonClicked={hasEndButtonClicked}
                    setEndButtonClicked={setEndButtonClicked}
                    quickMockJobId={quickMockJobId}
                />
                </>
            }
            {
                hasEndButtonClicked &&
                <>
                <CompleteInterview/>
                <Footer
                    isVideoOn={video}
                    isMicOn={mic}
                    onMicClick={setMic}
                    onVideoClick={setVideo}
                    onccClick={setCc}
                    hasUserJoined={hasUserJoined}
                    candidateEmail={candidateEmail}
                    round={globalRound}
                    interviewPromptId={interviewPromptId}
                    interviewQuestion={interviewQuestion}
                    hasEndButtonClicked={hasEndButtonClicked}
                    setEndButtonClicked={setEndButtonClicked}
                />
                </>
            }
        </> 
        );
    }
}

export default Interview;